import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
import Header from "components/Header/Header.jsx";
// import Button from "components/CustomButtons/Button.jsx";

import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.jsx";

class HeaderNew extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={`${classes.section} cd-section`} id="navigation">
            <Header
              brand="Timedrawer"
              fixed
              // links={
              //   <List className={classes.list + " " + classes.mlAuto}>
              //     <ListItem className={classes.listItem}>
              //       <Button
              //         href="#product"
              //         className={classes.navLink}
              //         onClick={e => e.preventDefault()}
              //         color="transparent"
              //       >
              //         Discover
              //       </Button>
              //     </ListItem>
              //     <ListItem className={classes.listItem}>
              //       <Button
              //         href="#contact"
              //         className={classes.registerNavLink}
              //         onClick={e => e.preventDefault()}
              //         color="info"
              //         round
              //       >
              //         Register
              //       </Button>
              //     </ListItem>
              //   </List>
              // }
            />
      </div>
    );
  }
}

export default withStyles(navbarsStyle)(HeaderNew);
