import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Inbox from "@material-ui/icons/Inbox";
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import People from "@material-ui/icons/People";
import Work from "@material-ui/icons/Work";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedIn";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.jsx";

class SectionProduct extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>Let's talk product</h2>
            <h5 className={classes.description}>
              Timedrawer is an easy to use software platform for easy creation, approval, and reporting
              on how employees bill time at work.  It contains a number of advanced features that help 
              your organization be more efficient and ensure compliance for all regulation and grant requirements.
            </h5>
            <h5 className={classes.description}>
              Make your auditors happy with Timedrawer.
            </h5>
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Timesheet Management"
                description="Users can submit timesheets based on projects and hours they have been assigned by various managers"
                icon={Inbox}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="User Management"
                description="Supports unlimited amount of users with built-in support for secure authentication and authorization"
                icon={Person}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Project Management"
                description="Captures detailed information on projects or grants - project managers can divy up hours to an unlimited number of employees"
                icon={Work}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Custom Project Assignments"
                description="Employee timesheet hours are derived by allocating a percentage of a project's hours to individual employees - allows detailed auditing of charges against a project"
                icon={AssignmentTurnedIn}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Roles and Permissions"
                description="Flexible user models and permissions allow clear distinctions between employees, managers, and system administrators"
                icon={People}
                iconColor="info"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                title="Data Insights"
                description="Detailed insights into project hours and how your employees are spending them"
                icon={Dashboard}
                iconColor="info"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(SectionProduct);
